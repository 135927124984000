import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/en',
    name: 'HomePageEN',
    component: Home,
    meta: {
      title: 'Home',
      locale: 'en'
    }
  },
  {
    path: '/ko',
    name: 'HomePageKO',
    component: Home,
    meta: {
      title: 'Home',
      locale: 'ko'
    }
  },
  {
    path: '/jp',
    name: 'HomePageJP',
    component: Home,
    meta: {
      title: 'Home',
      locale: 'jp'
    }
  },
  {
    path: '/',
    name: 'HomePage',
    component: Home,
    meta: {
      title: 'Home'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  window.document.title = `Deep Learning English- ${to.meta.title}`
  next()
})

export default router
