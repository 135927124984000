<template>
  <div class="hero" :class="$i18n.locale">
    <div class="hero--wrapper">
      <div class="hero--logo">
        <img
          src="@/assets/images/home/logoonly.png"
          alt="Logo"
          class="hero--logo-img"
          draggable="false"
        />
        <h1 class="hero--logo-text">{{ $t("hero.title") }}</h1>
      </div>
      <a
        href="https://play.google.com/store/apps/details?id=ai.mindlogic.deeplearningenglish"
        target="_blank"
        rel="noopener noreferrer"
        class="hero--playStore"
      >
        <img
          src="@/assets/images/home/play-store.svg"
          alt="PlayStore Logo"
          class="hero--playStore-img"
          draggable="false"
        />
        <span class="hero--playStore-text">Google Play</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
};
</script>
