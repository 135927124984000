<template>
  <div class="benefits" :class="$i18n.locale">
    <div class="benefits--wrapper">
      <h3 class="benefits--title" v-html="$t('benefits.title')" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Benefits",
};
</script>
