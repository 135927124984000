<template>
  <div class="ai" :class="$i18n.locale">
    <div class="ai--wrapper">
      <div class="ai--char ai--char_male">
        <div class="ai--top">
          <img
            src="@/assets/images/home/james.png"
            alt="James AI"
            class="ai--top-img"
            draggable="false"
          />
          <div class="ai--top-text">
            <h2
              class="ai--top-text-title"
              v-html="$t('ai.james.title')"
              v-animate
            />
            <h3 class="ai--top-text-subtitle" v-animate="150">
              {{ $t("ai.james.subtitle") }}
            </h3>
          </div>
        </div>
        <div class="ai--bubble">
          <p v-html="$t('ai.james.bubble')" />
        </div>
      </div>
      <div class="ai--char ai--char_female">
        <div class="ai--top">
          <img
            src="@/assets/images/home/angela.png"
            alt="Angela AI"
            class="ai--top-img"
            draggable="false"
          />
          <div class="ai--top-text">
            <h2
              class="ai--top-text-title"
              v-html="$t('ai.angela.title')"
              v-animate="300"
            />
            <h3 class="ai--top-text-subtitle" v-animate="450">
              {{ $t("ai.angela.subtitle") }}
            </h3>
          </div>
        </div>
        <div class="ai--bubble">
          <p v-html="$t('ai.angela.bubble')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AI",
};
</script>
