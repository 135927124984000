<template>
  <div class="becomeFluent" :class="$i18n.locale">
    <div class="becomeFluent--wrapper">
      <div class="becomeFluent--text">
        <div class="becomeFluent--bubble" v-animate>
          {{ $t("becomeFluent.bubble") }}
        </div>
        <div
          class="becomeFluent--title becomeFluent--title_excl"
          v-animate="100"
        >
          {{ $t("becomeFluent.titleExcl") }}
        </div>
        <div
          class="becomeFluent--title"
          v-html="$t('becomeFluent.title')"
          v-animate="175"
        />
      </div>
      <img
        :src="
          require(`@/assets/images/home/become-fluent${
            ['ko', 'jp'].includes(locale) ? '-' + locale : ''
          }.png`)
        "
        alt="Become Fluent"
        class="becomeFluent--img becomeFluent--img_mobile"
        draggable="false"
      />
      <img
        :src="
          require(`@/assets/images/home/become-fluent${
            ['ko', 'jp'].includes(locale) ? '-' + locale : ''
          }-lg.png`)
        "
        alt="Become Fluent"
        class="becomeFluent--img becomeFluent--img_desktop"
        draggable="false"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "BecomeFluent",
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>
