<template>
  <div class="screenshots" :class="$i18n.locale">
    <div class="screenshots--wrapper">
      <div v-for="(item, index) in list" :key="index" class="screenshots--item">
        <div class="screenshots--item-text">
          <h2 class="screenshots--item-text-title" v-html="item.title" />
          <h3 class="screenshots--item-text-subtitle">{{ item.subtitle }}</h3>
          <p
            class="screenshots--item-text-description"
            v-html="item.description"
          />
        </div>
        <img
          :src="item.image"
          alt="Screenshot image"
          class="screenshots--item-img"
          :class="{ 'screenshots--item-img_mobile': index === 1 }"
          draggable="false"
        />
        <img
          v-if="item.imageLg"
          :src="item.imageLg"
          alt="Screenshot image"
          class="screenshots--item-img screenshots--item-img_desktop"
          draggable="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Screenshots",
  computed: {
    list() {
      let locale = this.$i18n.locale;
      if (!["en", "ko", "jp"].includes(locale)) {
        locale = "en";
      }
      return [
        {
          ...this.$i18n.messages[locale].screenshots.list[0],
          image: require(`@/assets/images/home/screenshot-1${
            ["ko", "jp"].includes(locale) ? "-" + locale : ""
          }.png`),
        },
        {
          ...this.$i18n.messages[locale].screenshots.list[1],
          image: require(`@/assets/images/home/screenshot-2${
            ["ko", "jp"].includes(locale) ? "-" + locale : ""
          }.png`),
          imageLg: require(`@/assets/images/home/screenshot-2${
            ["ko", "jp"].includes(locale) ? "-" + locale : ""
          }-lg.png`),
        },
      ];
    },
  },
};
</script>
