import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n'
import messages from './locales'

import './assets/styles/main.scss'

const locale = navigator.language.split('-')[0]

const i18n = createI18n({
    locale,
    fallbackLocale: 'en',
    messages,
})

const app = createApp(App)

app.use(i18n)

app.use(router).use(store).directive('animate', {
    created(el, binding) {
        el.style.opacity = '0'
        el.style.transition = 'opacity 0.5s ease-in'


        const observer = new IntersectionObserver((items) => {
            items.forEach(e => {
                if (e.isIntersecting) {
                    if (binding.value) {
                        el.style.transitionDelay = binding.value + 'ms'
                    }
                    e.target.style.opacity = '1'
                }
            })
        }, {
            threshold: 1
        })

        observer.observe(el)
    },
}).mount('#app')
