<template>
  <div class="homePage">
    <div class="homePage--wrapper">
      <LanguageSwitch />
      <Hero />
      <Features />
      <Benefits />
      <BecomeFluent />
      <AI />
      <Screenshots />
      <Chat />
    </div>
  </div>
</template>

<script>
import LanguageSwitch from "@/components/pages/home/LanguageSwitch.vue";
import Hero from "@/components/pages/home/Hero.vue";
import Features from "@/components/pages/home/Features.vue";
import Benefits from "@/components/pages/home/Benefits.vue";
import BecomeFluent from "@/components/pages/home/BecomeFluent.vue";
import AI from "@/components/pages/home/AI.vue";
import Screenshots from "@/components/pages/home/Screenshots.vue";
import Chat from "@/components/pages/home/Chat.vue";

export default {
  name: "HomePage",
  components: {
    LanguageSwitch,
    Hero,
    Features,
    Benefits,
    BecomeFluent,
    AI,
    Screenshots,
    Chat,
  },
  mounted() {
    const locale = this.$route.meta.locale;
    if (locale) {
      this.$i18n.locale = locale;
    }
  },
};
</script>
