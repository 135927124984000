<template>
  <div class="chat" :class="$i18n.locale">
    <div class="chat--wrapper">
      <div class="chat--inner">
        <div class="chat--bubble" v-animate>{{ $t("chat.bubble") }}</div>
        <div class="chat--titleWrapper">
          <div class="chat--title" v-animate="100">
            {{ $t("chat.title") }}
          </div>
          <div class="chat--title chat--title_excl" v-animate="100">
            {{ $t("chat.titleExcl") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Chat",
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>
