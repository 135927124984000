<template>
  <footer class="footer">
    <a
      href="https://play.google.com/store/apps/details?id=ai.mindlogic.deeplearningenglish"
      target="_blank"
      rel="noopener noreferrer"
      class="footer--link"
      >{{ $t("footer.title") }}</a
    >
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
