export default {
    en: {
        hero: {
            title: 'Deep Learning English'
        },
        features: {
            title: `High Quality English Study Provided <br /> <span>by Native AI Tutors</span>`,
            list: [
                '<strong>Unlimited</strong> class hours',
                '<strong>Unlimited</strong> conversation topics',
                '<strong>Unlimited</strong> access to AI Tutors'
            ]
        },
        benefits: {
            title: 'Take all the benefits <br /> <span>for the price of just a cup of coffee: <strong>$4.99</strong></span>'
        },
        becomeFluent: {
            bubble: 'with native AI Tutors',
            title: 'Become <br /> Fluent <br /> in English',
            titleExcl: 'Chat!'
        },
        ai: {
            james: {
                title: 'James is <br /><span>AI</span>',
                subtitle: 'Supercar enthusiast',
                bubble: 'Ai Tutors created <br /> with deep learning technology'
            },
            angela: {
                title: 'Angela is <br /><span>AI</span>',
                subtitle: 'Fashionista',
                bubble: 'Unlimited English conversations <br /> with native AI Tutors'
            }
        },
        screenshots: {
            list: [
                {
                    title:
                        "Unlimited conversations <br /> about any topics of your choice",
                    subtitle: "with AI Tutors",
                    description:
                        "Choose from a wide range of topics <br /> such as business conversations, travel, fashion, etc",
                },
                {
                    title: "Talk anytime 24/7",
                    subtitle: "with AI Tutors",
                    description:
                        "AI Tutors instantly respond no matter what place or time. <br /> AI Tutors will send you Conversation Starters <br /> at a preset time of your choice",
                }
            ]
        },
        chat: {
            bubble: 'with native AI Tutors',
            title: 'Chat!',
            titleExcl: 'confidently!'
        },
        footer: {
            title: 'Start Your 1 Month Free Trial!'
        }
    },
    ko: {
        hero: {
            title: '딥러닝잉글리시'
        },
        features: {
            title: `<span>네이티브 AI튜터</span>들과 함께 하는 <br /> 고품격 영어학습`,
            list: [
                '수업 시간 <strong>무제한</strong>',
                '대화 주제 <strong>무제한</strong>',
                'AI튜터 선택 <strong>무제한</strong>'
            ]
        },
        benefits: {
            title: '이 모든 것을 <br /> <span>커피 한 잔 가격 <strong>월 4,900원에</strong></span>'
        },
        becomeFluent: {
            bubble: '네이티브 AI튜터와',
            title: '영어가 <br /> 자유로워 <br />질때까지',
            titleExcl: '챗하라!'
        },
        ai: {
            james: {
                title: '제임스도 <br /><span>AI</span>',
                subtitle: '슈퍼카마니아',
                bubble: '딥러닝 기술로 탄생한 네이티브 AI튜터 '
            },
            angela: {
                title: '안젤라도 <br /><span>AI</span>',
                subtitle: '패셔니스타',
                bubble: '무한 채팅으로 일대일 영어 학습'
            }
        },
        screenshots: {
            list: [
                {
                    title:
                        "원하는 주제로 무한대로",
                    subtitle: "AI튜터니까!",
                    description:
                        "비즈니스회화 부터 여행, 음식, 패션까지 <br /> 관심사에 딱 맞는 AI튜터 무제한 선택",
                },
                {
                    title: "24시간 원하는 시간에",
                    subtitle: "AI튜터니까!",
                    description:
                        "언제 어디서나 즉시 답변 <br /> 내가 정한 시간에 선톡을 보내는 AI튜터",
                }
            ]
        },
        chat: {
            bubble: '네이티브 AI튜터와',
            title: '챗하라!',
            titleExcl: '당당하게'
        },
        footer: {
            title: '1개월 무료 체험하기'
        }
    },
    jp: {
        hero: {
            title: 'Deep Learning English'
        },
        features: {
            title: `<span>ネイティブAIチューター</span>に学ぶ <br /> ハイクオリティ英語学習`,
            list: [
                'AIチューター選び放題',
                '好きな話題選び放題',
                'チャットし放題'
            ]
        },
        benefits: {
            title: 'この全てを <br /> <span><strong>490円、</strong>コーヒー1杯のお値段で!</span>'
        },
        becomeFluent: {
            bubble: 'ネイティブAIチューターと',
            title: '英語が <br /> ペラペラに <br /> なる時まで',
            titleExcl: 'チャットしよう'
        },
        ai: {
            james: {
                title: 'Jamesも <br /><span>AI</span>',
                subtitle: 'スㅡパㅡカㅡコレクタㅡ',
                bubble: 'ディープラーニング技術で開発された <br /> ネイティブAIチューター'
            },
            angela: {
                title: 'Angelaも <br /><span>AI</span>',
                subtitle: 'ファッションリㅡダㅡ',
                bubble: '好きな話題で1対1チャット英会話'
            }
        },
        screenshots: {
            list: [
                {
                    title:
                        "好きな話題を選び放題",
                    subtitle: "AIチューターだから",
                    description:
                        "ビジネス英会話から旅行、食べ物、ファッションなどなど! <br /> 共通の趣味をもったAIチューターをいくらでも選択できます",
                },
                {
                    title: "24時間好きな時間に",
                    subtitle: "AIチューターだから",
                    description:
                        "時間や場所に関係なく <br /> AIチューターはすぐに返事を返してくれます",
                }
            ]
        },
        chat: {
            bubble: 'ネイティブAIチューターと',
            title: 'チャットしよう!',
            titleExcl: '堂々と話そう!'
        },
        footer: {
            title: '初回1ヶ月無料体験'
        }
    }
}
