<template>
  <div class="languageSwitch">
    <div class="languageSwitch--inner">
      <div class="languageSwitch--mobile">
        <button
          class="languageSwitch--toggler"
          @click="dropdownVisible = !dropdownVisible"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 13 14"
          >
            <path
              fill="#979797"
              d="M6.494.5A6.497 6.497 0 000 7c0 3.588 2.905 6.5 6.494 6.5A6.504 6.504 0 0013 7C13 3.412 10.088.5 6.494.5zm4.504 3.9H9.081a10.173 10.173 0 00-.897-2.314A5.22 5.22 0 0110.998 4.4zM6.5 1.826c.54.78.962 1.644 1.242 2.574H5.258c.28-.93.702-1.794 1.242-2.574zM1.469 8.3A5.357 5.357 0 011.3 7c0-.449.065-.884.169-1.3h2.197c-.052.429-.091.858-.091 1.3 0 .442.039.871.091 1.3H1.469zm.533 1.3H3.92c.208.813.506 1.593.896 2.314A5.192 5.192 0 012.002 9.6zM3.92 4.4H2.002a5.192 5.192 0 012.814-2.314A10.173 10.173 0 003.92 4.4zm2.58 7.774A9.156 9.156 0 015.258 9.6h2.484A9.156 9.156 0 016.5 12.174zM8.021 8.3H4.979A9.563 9.563 0 014.875 7c0-.442.045-.878.104-1.3h3.042c.058.422.104.858.104 1.3 0 .442-.046.871-.104 1.3zm.162 3.614c.39-.721.69-1.502.898-2.314h1.917a5.22 5.22 0 01-2.814 2.314zM9.335 8.3c.052-.429.091-.858.091-1.3 0-.442-.039-.871-.091-1.3h2.197c.104.416.169.851.169 1.3 0 .449-.065.884-.169 1.3H9.334z"
            />
          </svg>
          <span>{{ activeLang }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 13 14"
          >
            <path
              fill="#000"
              d="M9.208 5.246a.542.542 0 00-.764 0L6.5 7.163 4.582 5.246a.542.542 0 10-.764.769L6.115 8.31a.542.542 0 00.77 0l2.323-2.296a.542.542 0 000-.77z"
            />
          </svg>
        </button>
        <div v-if="dropdownVisible" class="languageSwitch--dropdown">
          <button
            v-for="lang in nonActiveLangs"
            :key="lang.code"
            @click="
              dropdownVisible = false;
              $i18n.locale = lang.code;
              $router.push(`/${lang.code}`);
            "
          >
            {{ lang.label }}
          </button>
        </div>
      </div>
      <div class="languageSwitch--desktop">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 13 14">
          <path
            fill="#979797"
            d="M6.494.5A6.497 6.497 0 000 7c0 3.588 2.905 6.5 6.494 6.5A6.504 6.504 0 0013 7C13 3.412 10.088.5 6.494.5zm4.504 3.9H9.081a10.173 10.173 0 00-.897-2.314A5.22 5.22 0 0110.998 4.4zM6.5 1.826c.54.78.962 1.644 1.242 2.574H5.258c.28-.93.702-1.794 1.242-2.574zM1.469 8.3A5.357 5.357 0 011.3 7c0-.449.065-.884.169-1.3h2.197c-.052.429-.091.858-.091 1.3 0 .442.039.871.091 1.3H1.469zm.533 1.3H3.92c.208.813.506 1.593.896 2.314A5.192 5.192 0 012.002 9.6zM3.92 4.4H2.002a5.192 5.192 0 012.814-2.314A10.173 10.173 0 003.92 4.4zm2.58 7.774A9.156 9.156 0 015.258 9.6h2.484A9.156 9.156 0 016.5 12.174zM8.021 8.3H4.979A9.563 9.563 0 014.875 7c0-.442.045-.878.104-1.3h3.042c.058.422.104.858.104 1.3 0 .442-.046.871-.104 1.3zm.162 3.614c.39-.721.69-1.502.898-2.314h1.917a5.22 5.22 0 01-2.814 2.314zM9.335 8.3c.052-.429.091-.858.091-1.3 0-.442-.039-.871-.091-1.3h2.197c.104.416.169.851.169 1.3 0 .449-.065.884-.169 1.3H9.334z"
          />
        </svg>
        <button
          v-for="lang in languages"
          :key="lang.code"
          :class="{ active: activeLang === lang.label }"
          @click="
            $i18n.locale = lang.code;
            $router.push(`/${lang.code}`);
          "
        >
          {{ lang.label }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LanguageSwitch",
  data() {
    return {
      dropdownVisible: false,
      languages: [
        {
          label: "한국어",
          code: "ko",
        },
        {
          label: "English",
          code: "en",
        },
        {
          label: "日本語",
          code: "jp",
        },
      ],
    };
  },
  computed: {
    nonActiveLangs() {
      return this.languages.filter((e) => e.label !== this.activeLang);
    },
    activeLang() {
      return (
        this.languages.find(
          (e) => e.code.toLowerCase() === this.$i18n.locale.toLowerCase()
        )?.label || "English"
      );
    },
  },
};
</script>
