<template>
  <header class="header">
    <nav class="header--nav">
      <router-link to="/" aria-label="Home" class="header--logo">
        <img src="@/assets/images/home/logo.png" alt="Logo" draggable="false" />
      </router-link>
    </nav>
  </header>
</template>

<script>
export default {
  name: "LayoutHeader",
};
</script>
