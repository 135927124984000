<template>
  <div class="features" :class="$i18n.locale">
    <div class="features--wrapper">
      <h2 class="features--title" v-html="$t('features.title')" />
      <ul class="features--list">
        <li
          v-for="(item, index) in list"
          :key="index"
          class="features--list-item"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 19 19"
          >
            <path
              fill="#F87757"
              d="M18.938 16.897a2.042 2.042 0 01-2.04 2.041H2.04A2.041 2.041 0 010 16.897V2.04A2.042 2.042 0 012.041 0h14.856a2.042 2.042 0 012.041 2.041v14.856z"
            />
            <path
              fill="#fff"
              d="M15.992 4.198a1.383 1.383 0 00-1.948.152l-5.95 6.958-3.2-2.672a1.261 1.261 0 10-1.617 1.936l4.288 3.58a1.254 1.254 0 00.96.28 1.38 1.38 0 00.944-.479l6.675-7.808a1.383 1.383 0 00-.153-1.948"
            />
          </svg>
          <span v-html="item"></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Features",
  computed: {
    list() {
      let locale = this.$i18n.locale;
      if (!["en", "ko", "jp"].includes(locale)) {
        locale = "en";
      }
      return this.$i18n.messages[locale].features.list;
    },
  },
};
</script>